import React from "react";
import {useForm} from "react-hook-form";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import "./Contact.css";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: {errors}
  } = useForm();

  const onSubmit = async data => {
    const {name, email, subject, message} = data;

    console.log("Name: ", name);
    console.log("Email: ", email);
    console.log("Subject: ", subject);
    console.log("Message: ", message);

    const response = await fetch("https://formspree.io/f/xblrrkvq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      reset();
      Toastify({
        text: "Thank you for your message! I will get back to you soon.",
        background: "linear-gradient(to right, #00b09b, #96c93d)",
        className: "info"
      }).showToast();
    } else {
      Toastify({
        text: "Failed to send message",
        background: "linear-gradient(to right, #ff5f6d, #ffc371)",
        className: "info"
      }).showToast();
    }

    console.log("Response: ", response);
  };

  return (
    <div className="form-container">
      {/* <h2 className="contact-heading">Contact Us</h2>
      <p className="contact-desc">We would love to hear from you!</p> */}
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <input
          type="text"
          name="name"
          {...register("name", {
            required: {value: true, message: "Please enter your name"},
            maxLength: {value: 30, message: "Please use 30 characters or less"}
          })}
          placeholder="Name"
        />
        {errors.name && (
          <span style={{color: "red", fontSize: "12px"}}>
            {errors.name.message}
          </span>
        )}

        <input
          type="email"
          name="email"
          {...register("email", {
            required: true,
            pattern:
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          })}
          placeholder="Email address"
        />
        {errors.email && (
          <span style={{color: "red", fontSize: "12px"}}>
            Please enter a valid email address
          </span>
        )}

        <input
          type="text"
          name="subject"
          {...register("subject", {
            required: {value: true, message: "Please enter a subject"},
            maxLength: {
              value: 75,
              message: "Subject cannot exceed 75 characters"
            }
          })}
          placeholder="Subject"
        />
        {errors.subject && (
          <span style={{color: "red", fontSize: "12px"}}>
            {errors.subject.message}
          </span>
        )}

        <textarea
          rows={3}
          name="message"
          {...register("message", {required: true})}
          className="message-box"
          placeholder="Message"
        />
        {errors.message && (
          <span style={{color: "red", fontSize: "12px"}}>
            Please enter a message
          </span>
        )}

        <button type="submit" className="contact-send-btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
